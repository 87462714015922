/***
 * Adds a parameter to the URL that tells the destination site
 * not to display the locale overlay popup.
 * Also appends an affiliate param from the cookie if it is missing.
 ***/

define(['app', 'cookie'], function(app, cookie) {

  function init() {
    var links = document.querySelectorAll('.js-country-links');
    var i;

    for (i = 0; i < links.length; i++) {
      app.on('click', addUrlParameters, links[i]);
    }

  }

  function addUrlParameters(event) {

    var target_url = this.href;

    if (target_url.indexOf('?') === -1) {
      target_url += '?';
    } else {
      target_url += '&';
    }

    target_url += 'countrySelected=Y';

    if (target_url.indexOf('affil') === -1) {
      var affil = cookie.get('affil_V6');
      if (affil) {
        target_url += '&affil=' + affil;
      }
    }

    event.preventDefault();
    loadPage(target_url);

    return false;
  }

  function loadPage(url) {
    window.location.assign(url);
  }

  init();

  return {
    init: init,
    addUrlParameters: addUrlParameters,
    loadPage: loadPage
  };

});
